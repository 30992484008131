import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../components/Layout";

// eslint-disable-next-line
export const ResourceTemplate = ({
  title,
  helmet,
  link,
}) => {
  return (
    <section className="section">
      {helmet || ""}
      <div className="container content">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <h1 className="title is-size-2 has-text-weight-bold is-bold-light">
              {title}
            </h1>
            <a href={link}>{link}</a>
          </div>
        </div>
      </div>
    </section>
  );
};

ResourceTemplate.propTypes = {
  title: PropTypes.string,
  helmet: PropTypes.object,
  link: PropTypes.string,
};

const resource = ({ data }) => {
  const { markdownRemark: resource } = data;

  return (
    <Layout>
      <ResourceTemplate
        helmet={
          <Helmet titleTemplate="%s | Resource">
            <title>{`${resource.frontmatter.title}`}</title>
          </Helmet>
        }
        title={resource.frontmatter.title}
        link={resource.frontmatter.link}
      />
    </Layout>
  );
};

resource.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default resource;

export const resourcepageQuery = graphql`
  query ResourceByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        title
        link
      }
    }
  }
`;
